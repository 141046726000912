import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Body, Description, HelpText, Title } from '../styles';
import AccountMigrationFooter from '../components/AccountMigrationFooter';
import { useMigrationAccountSteps } from '../consts';

import {
  IInsuranceQuestionsKey,
  INSURANCE_QUESTIONS,
  OPTIONAL_INSURANCE_QUESTION,
} from './consts';
import { FormStyled, SupperAnnuationCheckBoxWrapper } from './styles';
import { useFormValidationSchema } from './useFormValidationSchema';

import { MIGRATION_FLOW } from 'constants/migrationConstants';
import { setRegistrationCompleted } from 'store/user/actions';
import { useStepper } from 'hooks/useStepper';
import { CheckBoxField } from 'components/form/checkbox/CheckboxField';
import { PATHS } from 'constants/paths';
import { selectIsSuperAnnuationInsuranceAccepted } from 'store/user/selectors';
import SuperRegistrationModal from 'components/layout/Modals/SuperRegistrationModal';
import getMigrationFlow from 'utils/getMigrationFlow';

export default function AccountMigrationInsurance() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSuperAnnuationInsuranceAccepted = useSelector(
    selectIsSuperAnnuationInsuranceAccepted,
  );

  const steps = useMigrationAccountSteps();
  const migrationFlow = getMigrationFlow();

  const [isSuperRegistrationModalOpen, setIsSuperRegistrationModalOpen] =
    useState(false);

  const schema = useFormValidationSchema(isSuperAnnuationInsuranceAccepted);

  const requiredQuestions = INSURANCE_QUESTIONS[migrationFlow].reduce(
    (acc, name) => ({
      ...acc,
      [name]: false,
    }),
    {} as Record<IInsuranceQuestionsKey, boolean>,
  );

  const optionalQuestions = isSuperAnnuationInsuranceAccepted
    ? {}
    : { [OPTIONAL_INSURANCE_QUESTION]: false };

  const form = useForm({
    defaultValues: { ...requiredQuestions, ...optionalQuestions },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, getValues } = form;

  const onProcessCompleted = useCallback(() => {
    navigate(PATHS.super.index);
  }, [navigate]);

  const { goNext } = useStepper({
    steps,
    onProcessCompleted,
  });

  const completeRegistration = () => {
    dispatch(
      setRegistrationCompleted({
        super_annuation_insurance: getValues(OPTIONAL_INSURANCE_QUESTION),
      }),
    );

    goNext();
  };

  const onSubmit = handleSubmit((values) => {
    if (
      !isSuperAnnuationInsuranceAccepted &&
      values.super_annuation_insurance
    ) {
      setIsSuperRegistrationModalOpen(true);
    } else {
      completeRegistration();
    }
  });

  const title =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.accountMigration.insurancePage.superEstateTitle')
      : t('registrationPage.accountMigration.insurancePage.title');

  const description =
    migrationFlow === MIGRATION_FLOW.superEstate &&
    isSuperAnnuationInsuranceAccepted
      ? t(
          'registrationPage.accountMigration.insurancePage.superEstateDescription',
        )
      : t('registrationPage.accountMigration.insurancePage.description');

  return (
    <FormProvider {...form}>
      <FormStyled onSubmit={onSubmit}>
        <Title>{title}</Title>

        {!isSuperAnnuationInsuranceAccepted && (
          <SupperAnnuationCheckBoxWrapper>
            <CheckBoxField
              name={OPTIONAL_INSURANCE_QUESTION}
              label={t(
                'registrationPage.accountMigration.insurancePage.supperAnnuationInsuranceQuestion',
              )}
            />
          </SupperAnnuationCheckBoxWrapper>
        )}

        <Description>{description}</Description>

        {migrationFlow !== MIGRATION_FLOW.superEstate && (
          <HelpText>{t('fields.validation.requiredFieldsInfo')}</HelpText>
        )}

        <Body>
          {INSURANCE_QUESTIONS[migrationFlow].map((question) => (
            <CheckBoxField
              key={question}
              name={question}
              label={t(
                `registrationPage.accountMigration.insurancePage.questions.${question}`,
              )}
            />
          ))}
        </Body>

        <AccountMigrationFooter />
      </FormStyled>

      {isSuperRegistrationModalOpen && (
        <SuperRegistrationModal
          isShowInsuranceModal={isSuperRegistrationModalOpen}
          handleCloseModal={() => setIsSuperRegistrationModalOpen(false)}
          handleSubmit={completeRegistration}
        />
      )}
    </FormProvider>
  );
}
