import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import LoginForRegistrationForm from '../../Login/components/LoginForRegistrationForm';
import {
  AU_APP,
  MY_APP,
  PRIVACY_POLICY,
  SUPPORT,
  TERMS_CONDITIONS,
  CURRENCY_SYMBOL,
} from '../../../constants/localeConfigs';
import { PLATFORM_LINK_MY } from '../../../constants/comonConstants';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

const scrollToRef = () => {
  const formHtml = document.getElementsByClassName('-invite-form');
  const formHtmlPostition = formHtml ? formHtml[0].offsetTop : 0;

  window.scrollTo({
    top: formHtmlPostition,
    left: 0,
    behavior: 'smooth',
  });
};

const Link = ({ href, title }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {title}
  </a>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default function StepAuth({
  login,
  errorMessage,
  user,
  loginData,
  handleChangeLogin,
  toggleShowPassword,
  isShowPassword,
  goToRegistration,
  goToLogin,
  showLoader,
  showDotsLoader,
  activeFieldInfo,
  validEmail,
  validConfirmEmail,
  isInvite,
  currentInviteCode,
  validPassword,
  stateMessages,
}) {
  const { t } = useTranslation();

  return (
    <div className={`login-page ${isInvite ? '-with-invite' : ''}`}>
      {isInvite && (
        <div className="invite-content__header">
          <div className="logo" />
        </div>
      )}

      {isInvite && (
        <div className={`invite-content__banner ${MY_APP ? '-my' : ''}`}>
          <div
            className={`invite-content__banner-title ${MY_APP ? '-my' : ''}`}
          >
            <Trans i18nKey="registrationPage.auth.tipsOnGrowing" t={t} />

            <div
              className={`invite-content__banner-btn ${
                MY_APP ? '-multi-btns' : ''
              }`}
            >
              <DeprecatedButton
                title={
                  <Trans i18nKey="registrationPage.auth.getStarted" t={t} />
                }
                buttonClass="button"
                handleClick={scrollToRef}
              />

              {MY_APP && (
                <a
                  href={PLATFORM_LINK_MY}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button__basic-fill -white -link"
                >
                  {t('registrationPage.auth.readTerms')}
                </a>
              )}
            </div>
          </div>
        </div>
      )}

      {isInvite && (
        <div className="invite-content__info">
          <div className="invite-content__info-title">
            <Trans
              i18nKey="registrationPage.auth.executing"
              t={t}
              components={{ span: <span /> }}
            />
          </div>

          <div className="invite-content__info-description">
            <Trans i18nKey="registrationPage.auth.executingDescription" t={t} />
          </div>
        </div>
      )}

      {isInvite && (
        <div className="invite-content__info -white">
          <div className="invite-content__info-title">
            <Trans i18nKey="login.investSpareChange" t={t} />
          </div>

          <div className="invite-content__info-description">
            <Trans
              i18nKey="registrationPage.auth.investSpareChangeDescription"
              t={t}
              values={{
                currencySymbol: CURRENCY_SYMBOL,
              }}
            />
          </div>
        </div>
      )}

      {isInvite && AU_APP && (
        <div className="invite-content__info -purple">
          <div className="invite-content__info-title">
            <span>{t('registrationPage.auth.raizSolution')}</span>
          </div>

          <div className="invite-content__info-description">
            <span>{t('registrationPage.auth.newInTime')}</span>
          </div>

          <div className="invite-content__info-title -with-margin">
            <span>{t('registrationPage.auth.takeaway')}</span>
          </div>

          <div className="invite-content__info-description">
            <Trans i18nKey="registrationPage.auth.savingAndInvesting" t={t} />
          </div>
        </div>
      )}

      {isInvite && MY_APP && (
        <div className="invite-content__info -purple">
          <div className="invite-content__info-title">
            <span>{t('registrationPage.auth.raizSolution')}</span>
          </div>

          <div className="invite-content__info-description -list">
            <span>{t('registrationPage.auth.firstReason')}</span>
          </div>

          <div className="invite-content__info-description -list">
            <span>{t('registrationPage.auth.secondReason')}</span>
          </div>

          <div className="invite-content__info-description -list">
            <span>{t('registrationPage.auth.thirdReason')}</span>
          </div>

          <div className="invite-content__info-title -with-margin">
            <span>{t('registrationPage.auth.takeaway')}</span>
          </div>

          <div className="invite-content__info-description">
            <Trans i18nKey="registrationPage.auth.savingAndInvesting" t={t} />
          </div>
        </div>
      )}

      {!isInvite && (
        <div className="get-started">
          <h4>{t('registrationPage.auth.createYourRaizAccount')}</h4>

          <div className="logo" />
        </div>
      )}

      <div className={`${isInvite ? '-invite-form' : ''}`}>
        <LoginForRegistrationForm
          validEmail={validEmail}
          validConfirmEmail={validConfirmEmail}
          validPassword={validPassword}
          isInvite={isInvite}
          currentInviteCode={currentInviteCode}
          activeFieldInfo={activeFieldInfo}
          showLoader={showLoader}
          showDotsLoader={showDotsLoader}
          handleSubmit={login}
          errorMessage={errorMessage}
          isRegistration
          user={user}
          loginData={loginData}
          handleChange={handleChangeLogin}
          toggleShowPassword={toggleShowPassword}
          isShowPassword={isShowPassword}
          goToRegistration={goToRegistration}
          goToLogin={goToLogin}
          stateMessages={stateMessages}
        />
      </div>

      <div>
        <div className="need-help-contact-u">
          <a href={SUPPORT} target="_blank" rel="noopener noreferrer">
            {t('registrationPage.auth.contactUs')}
          </a>
        </div>

        {!AU_APP && !isInvite && (
          <div className="need-help-terms">
            <Trans
              i18nKey="registrationPage.auth.byCreating"
              t={t}
              components={{
                toc: (
                  <Link
                    title={t('registrationPage.auth.conditions')}
                    href={TERMS_CONDITIONS}
                  />
                ),
              }}
            />
          </div>
        )}

        {AU_APP && !isInvite && (
          <div className="need-help-terms">
            <Trans
              i18nKey="registrationPage.auth.bySigning"
              t={t}
              components={{
                toc: (
                  <Link
                    title={t('registrationPage.auth.conditions')}
                    href={TERMS_CONDITIONS}
                  />
                ),
                privacy: (
                  <Link
                    title={t('registrationPage.auth.privacy')}
                    href={PRIVACY_POLICY}
                  />
                ),
              }}
            />
          </div>
        )}
      </div>

      {isInvite && (
        <div className="invite-content__footer">
          <div
            className={`invite-content__footer-title ${
              !AU_APP ? '' : '-margin-container'
            }`}
          >
            {t('registrationPage.auth.millennial')}
          </div>

          {AU_APP && (
            <div className="invite-content__footer-logo-container">
              <div className="invite-content__footer-logo -wired" />

              <div className="invite-content__footer-logo -forbes" />

              <div className="invite-content__footer-logo -news" />

              <div className="invite-content__footer-logo -cnbc" />

              <div className="invite-content__footer-logo -usnews" />
            </div>
          )}

          {AU_APP && <hr className="invite-content__hr" />}

          {AU_APP && (
            <div className="invite-content__footer-logo-container">
              <div className="invite-content__footer-logo -appsters" />

              <div className="invite-content__footer-logo -awards" />

              <div className="invite-content__footer-logo -sxsw" />
            </div>
          )}

          <div className="invite-content__footer-block">
            <div className="invite-content__footer-col">
              <div className="invite-content__footer-raiz-logo" />

              <div className="invite-content__footer-copy">
                {MY_APP && '© 2020 Raiz Malaysia Sdn Bhd'}

                {AU_APP && '© 2020 Raiz Invest Limited'}
              </div>
            </div>

            <div className="invite-content__footer-info">
              {t('login.investAustraliaLimited')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

StepAuth.propTypes = {
  login: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  user: PropTypes.shape({}).isRequired,
  loginData: PropTypes.shape({}).isRequired,
  handleChangeLogin: PropTypes.func.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isInvite: PropTypes.bool.isRequired,
  validEmail: PropTypes.bool,
  validConfirmEmail: PropTypes.bool,
  validPassword: PropTypes.bool,
  showDotsLoader: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  goToRegistration: PropTypes.func.isRequired,
  goToLogin: PropTypes.func.isRequired,
  activeFieldInfo: PropTypes.string.isRequired,
  currentInviteCode: PropTypes.string.isRequired,
  stateMessages: PropTypes.shape({}).isRequired,
};

StepAuth.defaultProps = {
  errorMessage: '',
  validEmail: false,
  validConfirmEmail: false,
  validPassword: false,
};
