import { useTranslation } from 'react-i18next';

import {
  BannerLabel,
  BannerSwitcherItem,
  BannerSwitcherWrapper,
  BannerTitle,
  BannerValue,
  BannerWrapper,
} from './styles';

import {
  STATEMENTS_MONTHLY,
  TAX_REPORTS,
  TRADE_CONFIRMATION,
} from 'constants/comonConstants';
import {
  KIDS_STATEMENTS_MONTHLY,
  KIDS_TAX_REPORTS,
  KIDS_TRADE_CONFIRMATION,
} from 'constants/kidsConstants';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUser } from 'store/user/selectors';
import { selectChild } from 'store/dependentUser/selectors';
import { STATEMETNS_TAX_ON } from 'constants/togglesFunctions';
import { IStatementsBannerProps } from 'pages/Statements/types';
import { useStatementsPath } from 'pages/Statements/hooks/useStatementsPath';
import { formatNumber } from 'utils/formatters/formatNumber';

function StatementsBanner({
  currentType,
  changeCurrentType,
}: IStatementsBannerProps) {
  const { t } = useTranslation();
  const { isRaizKids, isRaizSuper } = useStatementsPath();

  const user = useAppSelector(selectUser);
  const child = useAppSelector(selectChild);

  return (
    <BannerWrapper isRaizSuper={isRaizSuper}>
      <BannerTitle>{t('statements.title')}</BannerTitle>

      <BannerLabel>{t('statements.accountValue')}</BannerLabel>

      <BannerValue>
        {formatNumber({
          value:
            isRaizKids && child
              ? child.current_balance
              : user.current_balance || 0,
        })}
      </BannerValue>

      {!isRaizSuper && (
        <BannerSwitcherWrapper>
          <BannerSwitcherItem
            isActive={
              currentType === TRADE_CONFIRMATION ||
              currentType === KIDS_TRADE_CONFIRMATION
            }
            label={t('statements.confirmation')}
            onClick={() =>
              changeCurrentType(
                isRaizKids ? KIDS_TRADE_CONFIRMATION : TRADE_CONFIRMATION,
              )
            }
            variant="initial"
          />

          <BannerSwitcherItem
            label={t('statements.monthlyStatements')}
            onClick={() =>
              changeCurrentType(
                isRaizKids ? KIDS_STATEMENTS_MONTHLY : STATEMENTS_MONTHLY,
              )
            }
            isActive={
              currentType === STATEMENTS_MONTHLY ||
              currentType === KIDS_STATEMENTS_MONTHLY
            }
            variant="initial"
          />

          {STATEMETNS_TAX_ON && (
            <BannerSwitcherItem
              label={t('statements.tax_reports')}
              onClick={() =>
                changeCurrentType(isRaizKids ? KIDS_TAX_REPORTS : TAX_REPORTS)
              }
              isActive={
                currentType === TAX_REPORTS || currentType === KIDS_TAX_REPORTS
              }
              variant="initial"
            />
          )}
        </BannerSwitcherWrapper>
      )}
    </BannerWrapper>
  );
}

export default StatementsBanner;
