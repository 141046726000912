import { MIGRATION_FLOW } from 'constants/migrationConstants';
import aus from 'i18n/locales/en-au.json';

export type IAccountQuestions =
  typeof aus.registrationPage.accountQuestions.questions;

export type IAccountQuestionsDictionary = {
  [key in keyof IAccountQuestions]: keyof IAccountQuestions;
};

const QUESTIONS_KEY = Object.keys(
  aus.registrationPage.accountQuestions.questions,
).reduce(
  (acc, questionKey) => ({
    ...acc,
    [questionKey]: questionKey,
  }),
  {} as IAccountQuestionsDictionary,
);

export const ACCOUNT_QUESTIONS = {
  [MIGRATION_FLOW.superEstate]: [
    QUESTIONS_KEY.question_1,
    QUESTIONS_KEY.question_2,
    QUESTIONS_KEY.question_3,
    QUESTIONS_KEY.question_4,
    QUESTIONS_KEY.question_5,
    QUESTIONS_KEY.question_6,
    QUESTIONS_KEY.question_7,
  ],
  [MIGRATION_FLOW.standard]: [
    QUESTIONS_KEY.question_1,
    QUESTIONS_KEY.question_2,
    QUESTIONS_KEY.question_3,
    QUESTIONS_KEY.question_8,
    QUESTIONS_KEY.question_6,
  ],
} as const;
