export const CAMPAIGN_TYPES = {
  regular: 'regular',
  instore: 'instore',
};

export const ZAKAT_TYPES = {
  pendapatan: 'pendapatan',
  saham: 'saham',
  fitrah: 'fitrah',
};
