import 'dayjs/locale/id';
import 'dayjs/locale/en';
import 'dayjs/locale/ms-my';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import arraySupport from 'dayjs/plugin/arraySupport';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { APP_DATE_LOCALE } from '../constants/localeConfigs';

dayjs.locale(APP_DATE_LOCALE);

dayjs.extend(localeData);

dayjs.extend(utc);

dayjs.extend(timezone);

dayjs.extend(arraySupport);

dayjs.extend(advancedFormat);

dayjs.extend(duration);

dayjs.extend(customParseFormat);

dayjs.extend(isSameOrAfter);

dayjs.extend(isSameOrBefore);

// TODO: probably we should condider disabling this rule globally
// eslint-disable-next-line import/prefer-default-export
export const DATE_FORMAT = 'DD MMMM YYYY';
export const DATE_FORMAT_WITH_SHORT_MONTH = 'DD MMM YYYY';
export const DATE_FORMAT_WITH_DASHES_SHORT_MONTH = 'DD-MMM-YYYY';
export const DATE_FORMAT_WITH_TIME = 'YYYY-MM-DD HH:mm:ss';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_YEAR = 'YYYY';
export const DATE_FORMAT_DAY_NAME = 'dddd';
export const DATE_FORMAT_DAY_NUMBER = 'Do';
export const DATE_FORMAT_MONTH_YEAR = 'MMMM YYYY';
export const DATE_FORMAT_MONTH_DAY_NUMBER_YEAR = 'MMMM Do YYYY';
export const DATE_FORMAT_MONTH_DAY_NUMBER_DAY_NAME = 'MMMM Do dddd';
export const DATE_FORMAT_DAY_NAME_DAY_NUMBER_MONTH = 'dddd, Do MMMM';
export const DATE_FORMAT_MONTH_DASH_YEAR = 'MMMM-YYYY';
export const DATE_FORMAT_MONTH_DAY_NUMBER = 'MMMM Do';
export const DATE_FORMAT_WEEK_DAY_MONTH = 'dddd DD MMMM';
export const DATE_FORMAT_MONTH_DAY_YEAR = 'MMMM DD, YYYY';
export const DATE_FORMAT_SHORT_MONTH_DAY_YEAR = 'MMM D, YYYY';
export const DATE_FORMAT_DAY_MONTH_YEAR = 'DD MMM, YYYY';
export const DATE_FORMAT_HOUR_MINUTE_AM_PM_MARKER = 'hh:mm A';
export const DATE_FORMAT_DAY_MONTH_YEAR_WITH_SLASH = 'DD/MM/YYYY';
export const DATE_FORMAT_MONTH_DOT_DAY_COMMA_YEAR = 'MMM. DD, YYYY';
export const DATE_FORMAT_MYKAD = 'YYMMDD';
