import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import StatementsSendMail from '../StatementsSendMail/StatementsSendMail';

import {
  StatementsInfoPaneLeftWrapper,
  Header,
  Content,
  DownloadButton,
  OpenButton,
  StatementsButtonWrapper,
} from './styles';
import { useDownloadStatement } from './hooks';
import { IStatementProps } from './types';

import { TRADE_CONFIRMATION } from 'constants/comonConstants';
import { KIDS_TRADE_CONFIRMATION } from 'constants/kidsConstants';
import Time from 'components/elements/Time/Time';
import { MY_APP } from 'constants/localeConfigs';
import { DATE_FORMAT_YEAR } from 'config/date';
import { useStatementsPath } from 'pages/Statements/hooks/useStatementsPath';

function Statement({
  prev,
  statement,
  currentType,
  openPdfModal,
  isStatementsNested,
  isCheckboxesVisible,
  onStatementSelect,
  isChecked,
  sendStatements,
}: IStatementProps) {
  const { t } = useTranslation();

  const { currentStatementId, handleDownloadStatement, isLoading } =
    useDownloadStatement({
      currentType,
    });

  const { isRaizKids, isRaizSuper } = useStatementsPath();

  const prevYear = prev && Object.keys(prev).length > 0 ? prev.year : '';

  const statementDate = isStatementsNested
    ? statement.date
    : statement.created_at;

  const prevStatementYear =
    isStatementsNested && prev && Object.keys(prev).length > 0
      ? dayjs(prev.date).format(DATE_FORMAT_YEAR)
      : prevYear;

  const statementYear = isStatementsNested
    ? dayjs(statement.date).format(DATE_FORMAT_YEAR)
    : statement.year;

  const statementId = isRaizSuper ? statement.uuid : statement.id;

  return (
    <>
      {prevStatementYear !== statementYear && <Header>{statementYear}</Header>}

      <Content>
        <StatementsInfoPaneLeftWrapper>
          {MY_APP && isRaizKids && (
            <StatementsSendMail
              statement={statement}
              isCheckboxesVisible={isCheckboxesVisible}
              onStatementSelect={onStatementSelect}
              isChecked={isChecked}
              sendStatements={sendStatements}
            />
          )}

          {currentType !== TRADE_CONFIRMATION &&
            currentType !== KIDS_TRADE_CONFIRMATION &&
            !isRaizSuper && <Time format="MMMM">{statementDate}</Time>}

          {(currentType === TRADE_CONFIRMATION ||
            currentType === KIDS_TRADE_CONFIRMATION ||
            isRaizSuper) && <Time format="MMMM Do">{statementDate}</Time>}
        </StatementsInfoPaneLeftWrapper>

        <StatementsButtonWrapper>
          {!isStatementsNested ? (
            <DownloadButton
              label={t('statements.downloadPDF')}
              isLoading={isLoading && currentStatementId === statementId}
              onClick={() =>
                handleDownloadStatement({
                  id: statementId,
                  createdAt: statementDate,
                })
              }
              variant="initial"
            />
          ) : null}

          <OpenButton
            label={t('statements.openPDF')}
            onClick={() => {
              openPdfModal({ isModalOpen: true, id: statementId, statement });
            }}
            variant="initial"
          />
        </StatementsButtonWrapper>
      </Content>
    </>
  );
}

export default Statement;
