import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import '../../styles/pages/rewards.scss';
import { REWARDS } from '../../constants/comonConstants';
import HelpCardsModalAU from '../../components/layout/Modals/HelpCardsModalAU';

import BrandsCarousel from './components/BrandsCarousel';
import { FAVOURITE_CATEGORY_ID, OFFER_TYPES } from './consts';
import { RewardsListHeader } from './components/RewardsListHeader/RewardsListHeader';
import { SearchIconWrapper } from './styles';
import { RewardsList } from './components/RewardsList/RewardsList';
import CategoriesView from './components/CategoriesView';
import { useRewardsCurrentTab } from './hooks/useRewardsCurrentTab';
import { useAutoRewardInfoModal } from './hooks/useAutoRewardInfoModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';
import { PATHS } from 'constants/paths';
import { IconButton } from 'components/elements/IconButton';
import { useOffersQuery } from 'store/rewards/api';

export default function RewardsNewView({
  saveInvested,
  isOpenHelpCards,
  toggleHelpCard,
  type,
  showMainLoader,
  cashback,
  featuredOffers,
  categories,
  accountSummary,
  categoryName,
  categoryId,
  isHaveCard,
  toggleCategory,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);

  const { currentOfferType } = useRewardsCurrentTab();

  const offers = useOffersQuery(
    {
      offerType: currentOfferType,
      pageNumber,
      ...(categoryId && { categoryId }),
    },
    { skip: !currentOfferType || categoryId === FAVOURITE_CATEGORY_ID },
  );

  const { handleOpenAutoRewardsInfoModal } = useAutoRewardInfoModal();

  const handleOfferClick = (offer, isRecommended) => {
    if (offer.offerType === OFFER_TYPES.auto && !offer.sourceActive) {
      handleOpenAutoRewardsInfoModal(offer);

      return;
    }

    const pathname = isRecommended
      ? PATHS.rewards.online.recommended
      : PATHS.rewards.online.featured;

    navigate(
      generatePath(pathname, {
        id: offer.id || offer.offerId,
      }),
    );
  };

  return (
    <div className="rewards new-rewards">
      <div className="rewards__banner">
        <SearchIconWrapper>
          <IconButton
            iconName="searchIconWhite"
            onClick={() => navigate(PATHS.rewards.search)}
            size={24}
            isDisabled={offers.isFetching}
          />
        </SearchIconWrapper>

        <DeprecatedButton
          buttonClass="rewards__banner-info my-finance__banner-info"
          handleClick={() => toggleHelpCard(true)}
        />

        <div className="rewards__banner-title">{t('newRewardsPage.title')}</div>

        <div>
          <div className="invest-info">
            <div className="rewards__banner-label">
              {t('newRewardsPage.invested')}
            </div>

            <div className="rewards__banner-value">
              {formatNumber({
                value: accountSummary?.total_found_money_rewarded?.value
                  ? accountSummary.total_found_money_rewarded.value
                  : 0,
              })}
            </div>
          </div>

          <div className="invest-info">
            <div className="rewards__banner-label">
              {t('common.status.pending')}
            </div>

            <div className="rewards__banner-value">
              {formatNumber({
                value: cashback.pending,
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="new-rewards__carusel">
        <div className="rewards__banner-subtitle">
          {t('newRewardsPage.featuredOffers')}
        </div>

        <div className="slider-block">
          <BrandsCarousel
            features={featuredOffers?.trendingOffers}
            saveInvested={saveInvested}
            type={type}
            isNewRewards
            handleOfferClick={handleOfferClick}
          />
        </div>

        <div className="recommended">
          <div className="rewards__banner-subtitle">
            {t('newRewardsPage.recommendedForYou')}
          </div>

          <BrandsCarousel
            features={featuredOffers?.recommendedOffers}
            saveInvested={saveInvested}
            type={type}
            isRecommended
            isNewRewards
            handleOfferClick={handleOfferClick}
          />
        </div>
      </div>

      <div className="offers__panel">
        <RewardsListHeader
          categoryName={categoryName}
          setPageNumber={setPageNumber}
          isFetching={offers.isFetching}
        />

        {categories?.categories && (
          <CategoriesView
            categories={categories.categories}
            isSearchFromMainPage
            categoryId={categoryId}
            filterCategory={toggleCategory}
            isDisabled={offers.isFetching}
            setPageNumber={setPageNumber}
          />
        )}

        <div className="offers__panel-grid">
          <RewardsList
            isFavouriteCategory={categoryId === FAVOURITE_CATEGORY_ID}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            categoryName={categoryName}
            offers={offers}
          />

          {!showMainLoader && (
            <HelpCardsModalAU
              currentPage={REWARDS}
              toggleHelpCard={toggleHelpCard}
              isOpenHelpCards={isOpenHelpCards}
              isHaveCard={isHaveCard}
              isNewRewards
            />
          )}
        </div>
      </div>
    </div>
  );
}

RewardsNewView.defaultProps = {
  showMainLoader: false,
  type: '',
};

RewardsNewView.propTypes = {
  showMainLoader: PropTypes.bool,
  categoryTitle: PropTypes.shape({}).isRequired,
  categories: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  saveInvested: PropTypes.func.isRequired,
  toggleHelpCard: PropTypes.func.isRequired,
  isOpenHelpCards: PropTypes.bool.isRequired,
  type: PropTypes.string,
  cashback: PropTypes.shape({
    pending: PropTypes.number,
  }).isRequired,
  featuredOffers: PropTypes.shape({
    trendingOffers: PropTypes.arrayOf(PropTypes.shape({})),
    recommendedOffers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  accountSummary: PropTypes.shape({
    total_found_money_rewarded: PropTypes.shape({
      value: PropTypes.number,
    }),
  }).isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryId: PropTypes.number.isRequired,
  isHaveCard: PropTypes.bool.isRequired,
  toggleCategory: PropTypes.func.isRequired,
};
