import { FastlinkContent } from './components/FastlinkContent';
import { IFastlinkModalProps } from './types';
import { FASTLINK_CDN_URL, FASTLINK_FLOWS } from './consts';

import { useExternalScript } from 'hooks/useExternalScript';
import { BaseModalWrapper } from 'components/elements/ModalWrapper';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectCurrentBank,
  selectFastlinkFlow,
} from 'store/fundingAccount/selectors';

export const FastlinkModal = ({
  isFastlinkModalOpen,
  toggleFastlinkModal,
  type,
}: IFastlinkModalProps) => {
  useExternalScript(FASTLINK_CDN_URL);

  const bank = useAppSelector(selectCurrentBank);

  const fastlinkFlow = useAppSelector(selectFastlinkFlow);

  return (
    <BaseModalWrapper
      isOpen={isFastlinkModalOpen}
      onRequestClose={toggleFastlinkModal}
    >
      {fastlinkFlow !== FASTLINK_FLOWS.manageConsent &&
        (bank?.error_info?.error_message ?? null)}

      <FastlinkContent toggleFastlinkModal={toggleFastlinkModal} type={type} />
    </BaseModalWrapper>
  );
};
