import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import DOMPurify from 'dompurify';

import GiftCardModal from '../../../components/layout/Modals/GiftCardModal';
import { RAIZ_REWARD_PURE_PROFILE } from '../../../constants/comonConstants';
import { OFFER_SOURCES, OFFER_TYPES } from '../consts';

import RewardsErrorModal from './RewardsErrorModal';
import { RewardStatus } from './RewardStatus/RewardStatus';
import { RewardDetailsOfferStatusWrapper } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useOfferByIdQuery } from 'store/rewards/api';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';

function locationRender(location, key) {
  if (key === 0) {
    return <div key={location.id} />;
  }

  const url = `https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" key={location.id}>
      <div className="locations__items">
        <div>{location.address}</div>
      </div>
    </a>
  );
}

export default function NewRewardInternalView({
  isOnline,
  toggleLikeOffer,
  pureProfileLogin,
  isShowMoreLocation,
  showMoreLocation,
  isFeatured,
  isRecommended,
  isGiftCardModalVisible,
  giftCardModalToggle,
  user,
  offer: instoreOffer,
}) {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { data: offer } = useOfferByIdQuery(
    { offerId: id },
    { skip: !id || !isOnline },
  );

  const { t } = useTranslation();

  const currentOffer = isOnline ? offer : instoreOffer;

  const { latitude, longitude, address } = currentOffer?.locations?.[0] || {};

  const isOfferWithLatitude = currentOffer?.locations?.[0]?.latitude;

  const location = isOfferWithLatitude ? `${latitude},${longitude}` : null;

  const urlDestination = isOfferWithLatitude
    ? `https://maps.google.com/?q=${address}&ll=${latitude},${longitude}`
    : null;

  let isPureProfile = false;

  const buttonTitle = useMemo(() => {
    if (currentOffer?.offerType === OFFER_TYPES.auto) {
      if (currentOffer?.sourceType === OFFER_SOURCES.card) {
        return t('newRewardsPage.newRewardInternal.button.card');
      }

      return t('newRewardsPage.newRewardInternal.button.account');
    }

    return currentOffer?.ctaDetails?.title;
  }, [currentOffer, t]);

  if (currentOffer?.ctaDetails?.web?.includes(RAIZ_REWARD_PURE_PROFILE)) {
    isPureProfile = true;
  }

  const handleOpenAutoRewardsInfoModal = () => {
    const key =
      currentOffer?.sourceType === OFFER_SOURCES.card ? 'card' : 'account';

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t(
            `newRewardsPage.newRewardInternal.automaticRewardInfoModal.title.linked.${key}`,
          ),
          description: t(
            `newRewardsPage.newRewardInternal.automaticRewardInfoModal.description.linked.${key}`,
          ),
          acceptButtonText: t('common.ok'),
        },
      }),
    );
  };

  return (
    <div className="rewards-internal-new">
      {!isShowMoreLocation && (
        <div>
          {currentOffer?.id ? (
            <div className="reward-internal__banner reward-internal__banner__new">
              <div>
                {isFeatured && (
                  <div className="grid__carousel-feature">
                    {t('newRewardsPage.newRewardInternal.featured')}
                  </div>
                )}

                {isRecommended && (
                  <div className="grid__carousel-feature grid__carousel-recommended">
                    {t('newRewardsPage.newRewardInternal.recommended')}
                  </div>
                )}

                <img
                  className="reward-internal__banner-background reward-internal__banner-background__new"
                  src={currentOffer?.coverArt}
                  alt={currentOffer?.advertiser}
                  onError={(e) => {
                    e.target.style.display = 'none';

                    return true;
                  }}
                />

                <div
                  className="like"
                  onClick={() => {
                    toggleLikeOffer({
                      isFavourite: currentOffer?.isFavourite,
                      id: currentOffer?.id,
                      isInstore: !isOnline,
                      isShowFavourite: true,
                      isInternal: true,
                    });
                  }}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <div
                    className={
                      currentOffer?.isFavourite
                        ? 'like-active'
                        : 'like-inactive'
                    }
                  />
                </div>

                {currentOffer?.offerType === OFFER_TYPES.auto && (
                  <RewardDetailsOfferStatusWrapper
                    type="button"
                    onClick={handleOpenAutoRewardsInfoModal}
                  >
                    <RewardStatus
                      isActive={currentOffer?.sourceActive}
                      isReversedColors
                    />
                  </RewardDetailsOfferStatusWrapper>
                )}
              </div>

              <div className="reward-internal__banner-icon reward-internal__banner-icon__new">
                <img
                  src={currentOffer?.logo}
                  alt={currentOffer?.advertiser}
                  onError={(e) => {
                    e.target.style.display = 'none';

                    return true;
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="reward-internal__banner">
              <Skeleton width="150%" borderRadius="0" />
            </div>
          )}

          <div className="reward-internal__banner__title">
            <div className="brand">{currentOffer?.advertiser}</div>

            <div className="invest">{currentOffer?.title}</div>

            <div>{currentOffer?.description}</div>
          </div>

          {location && (
            <div className="reward-internal__details">
              <div className="info-panel -wide">
                <div className="info-panel__block">
                  <Accordion allowZeroExpanded preExpanded={['location']}>
                    <AccordionItem key="location" uuid="location">
                      <AccordionItemHeading>
                        <AccordionItemButton className="my-finance__modal-category">
                          <div className="info-panel__block-label">
                            {t('newRewardsPage.newRewardInternal.location')}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div className="info-panel__divider" />

                        <div className="info-panel__block-content">
                          <a
                            href={urlDestination}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <StaticGoogleMap
                              size="1280x150"
                              className="img-fluid"
                              scale="2"
                              apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                            >
                              <Marker
                                location={location}
                                color="0x0AAE77"
                                scale="2"
                              />
                            </StaticGoogleMap>
                          </a>

                          {currentOffer?.locations &&
                            currentOffer?.locations.length >= 2 && (
                              <div className="more-location">
                                <div className="more-location__address">
                                  {currentOffer?.locations[0].address}
                                </div>

                                <div
                                  className="more-location__btn"
                                  onClick={showMoreLocation}
                                  onKeyPress={() => {}}
                                  role="button"
                                  tabIndex="0"
                                >
                                  {t('newRewardsPage.newRewardInternal.more')}
                                </div>
                              </div>
                            )}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {currentOffer?.howItWorks && (
            <div className="reward-internal__details">
              <div className="info-panel -wide">
                <div className="info-panel__block">
                  <Accordion
                    allowZeroExpanded
                    preExpanded={location ? [] : ['howItWorks']}
                  >
                    <AccordionItem key="howItWorks" uuid="howItWorks">
                      <AccordionItemHeading>
                        <AccordionItemButton className="my-finance__modal-category">
                          <div className="info-panel__block-label">
                            {currentOffer?.isAGiftCard
                              ? t('newRewardsPage.newRewardInternal.tips')
                              : t('newRewardsPage.newRewardInternal.how')}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div className="info-panel__divider" />

                        <div className="info-panel__block-content">
                          {currentOffer?.howItWorks}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {currentOffer?.about && (
            <div className="reward-internal__details">
              <div className="info-panel -wide">
                <div className="info-panel__block">
                  <Accordion allowZeroExpanded>
                    <AccordionItem key="aboutTheBrand">
                      <AccordionItemHeading>
                        <AccordionItemButton className="my-finance__modal-category">
                          <div className="info-panel__block-label">
                            {currentOffer?.isAGiftCard
                              ? t('newRewardsPage.newRewardInternal.gift')
                              : t('newRewardsPage.newRewardInternal.brand')}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div className="info-panel__divider" />

                        <div className="info-panel__block-content">
                          {currentOffer?.about}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {currentOffer?.termsAndConditions && (
            <div className="reward-internal__details">
              <div className="info-panel -wide">
                <div className="info-panel__block">
                  <Accordion allowZeroExpanded>
                    <AccordionItem key="termsConditions">
                      <AccordionItemHeading>
                        <AccordionItemButton className="my-finance__modal-category">
                          <div className="info-panel__block-label">
                            {t('common.termsAndConditions')}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div className="info-panel__divider" />

                        <div className="info-panel__block-content">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                currentOffer?.termsAndConditions,
                                {
                                  FORCE_BODY: true,
                                  ADD_TAGS: ['script'],
                                },
                              ),
                            }}
                          />
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {currentOffer?.ctaDetails && currentOffer?.ctaDetails.web && (
            <div className="buttons-part">
              {!isPureProfile && (
                <a
                  href={currentOffer?.ctaDetails.web}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DeprecatedButton
                    title={buttonTitle}
                    buttonClass="button button--submit"
                  />
                </a>
              )}

              {isPureProfile && (
                <DeprecatedButton
                  title={currentOffer?.ctaDetails.title}
                  buttonClass="button__basic-fill"
                  handleClick={pureProfileLogin}
                />
              )}
            </div>
          )}

          {currentOffer?.isAGiftCard && (
            <div className="buttons-part">
              <DeprecatedButton
                title={t('newRewardsPage.newRewardInternal.buy')}
                buttonClass="button__basic-fill"
                handleClick={giftCardModalToggle}
              />
            </div>
          )}
        </div>
      )}

      {isShowMoreLocation && currentOffer?.locations?.length !== 0 && (
        <div className="in-store rewards-search">
          <div className="profile-content settings__content-default-container">
            <div className="profile-content__header -with-navigation ">
              <div className="profile-content__title">
                <div>{t('newRewardsPage.newRewardInternal.more')}</div>
              </div>
            </div>

            <div className="locations">
              {currentOffer?.locations.map((moreLocation, key) =>
                locationRender(moreLocation, key),
              )}
            </div>
          </div>
        </div>
      )}

      {isGiftCardModalVisible && user?.uuid && (
        <GiftCardModal
          isGiftCardModalVisible={isGiftCardModalVisible}
          giftCardId={currentOffer?.giftCardId}
          giftCardModalToggle={giftCardModalToggle}
          userUuid={user.uuid}
        />
      )}

      <RewardsErrorModal />
    </div>
  );
}

NewRewardInternalView.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  pureProfileLogin: PropTypes.func.isRequired,
  isShowMoreLocation: PropTypes.bool.isRequired,
  showMoreLocation: PropTypes.func.isRequired,
  isFeatured: PropTypes.bool.isRequired,
  isRecommended: PropTypes.bool.isRequired,
  isGiftCardModalVisible: PropTypes.bool.isRequired,
  giftCardModalToggle: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    advertiserId: PropTypes.number,
    offerId: PropTypes.number,
    coverArt: PropTypes.string,
    coverShotUrl: PropTypes.string,
    description: PropTypes.string,
    advertiser: PropTypes.string,
    terms: PropTypes.string,
    howItWorks: PropTypes.string,
    offerTitle: PropTypes.string,
    advertiserAboutUs: PropTypes.string,
    isFavourite: PropTypes.bool,
    logoUrl: PropTypes.string,
    advertiserName: PropTypes.string,
    termsAndConditions: PropTypes.string,
    isAGiftCard: PropTypes.bool,
    giftCardId: PropTypes.string,
    ctaDetails: PropTypes.shape({
      android: PropTypes.string,
      web: PropTypes.string,
      title: PropTypes.string,
    }),
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        address: PropTypes.string,
      }),
    ),
  }).isRequired,
};
