import { Icon } from 'components/elements/Icon';
import { OFFER_SOURCES } from 'pages/NewRewards/consts';

interface IRewardStatusIconProps {
  isActive: boolean;
  sourceType: string;
}

export const RewardStatusIcon = ({
  isActive,
  sourceType,
}: IRewardStatusIconProps) => {
  const isSourceTypeRoundUp = sourceType === OFFER_SOURCES.round_up_account;
  const isSourceTypeCard = sourceType === OFFER_SOURCES.card;

  if (isSourceTypeRoundUp) {
    return <Icon iconName={isActive ? 'bankGreen' : 'rewardBank'} size={12} />;
  }

  if (isSourceTypeCard) {
    return (
      <Icon
        iconName={isActive ? 'rewardCardGreen' : 'rewardInactive'}
        size={12}
      />
    );
  }

  return null;
};
