import { isEqual } from 'lodash';

import { API_TAGS } from '../../consts';

import transformZakatTypesResponse from './utils/transformZakatTypesResponse';
import {
  IGetRewardsResponse,
  IGetRewardsParams,
  IRecurringRemittanceSettingsResponse,
  IZakatTypeTransformed,
  IRecurringRemittanceSettingsData,
  IZakatTypeRequest,
  IUserOffersResponse,
  IUserOffersParams,
  IFavouritesOffersResponse,
  IUserOffer,
  IUserOfferByIdParams,
} from './types';

import { api } from 'store/api';
import { OFFER_TYPES } from 'pages/NewRewards/consts';

export const rewardsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRewards: build.query<IGetRewardsResponse, IGetRewardsParams>({
      query: ({ fulfilled = true }) => ({
        url: '/mobile/v1/rewards',
        method: 'GET',
        params: {
          fulfilled,
        },
        invalidatesTags: (
          _result: unknown,
          _error: unknown,
          params: IGetRewardsParams,
        ) => [{ type: 'rewards', fulfilled: params.fulfilled }],
      }),
    }),
    recurringRemittanceSettings: build.query<
      IRecurringRemittanceSettingsResponse,
      void
    >({
      query: () => ({
        url: '/v1/recurring_remittance_settings',
      }),
      providesTags: [API_TAGS.recurringRemittanceSettings],
    }),
    remittancesZakatTypes: build.query<
      IZakatTypeTransformed[],
      IZakatTypeRequest | void
    >({
      query: () => ({
        url: '/v1/remittances/zakat_types',
      }),
      transformResponse: transformZakatTypesResponse,
    }),
    updateRecurringRemittanceSettings: build.mutation<
      IZakatTypeTransformed[],
      IRecurringRemittanceSettingsData
    >({
      query: (data) => ({
        url: '/v1/recurring_remittance_settings',
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [API_TAGS.recurringRemittanceSettings],
    }),
    deleteRecurringRemittanceSettings: build.mutation<
      IZakatTypeTransformed[],
      void
    >({
      query: () => ({
        url: '/v1/recurring_remittance_settings',
        method: 'DELETE',
      }),
      invalidatesTags: [API_TAGS.recurringRemittanceSettings],
    }),
    offers: build.query<IUserOffersResponse, IUserOffersParams>({
      query: ({
        offerType = OFFER_TYPES.online,
        pageNumber = 1,
        categoryId,
        query,
      }) => ({
        url: '/v1/user_offers/offers',
        params: {
          offerType,
          pageNumber,
          categoryId,
          query,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCacheData, responseData, { arg }) => {
        if (arg?.pageNumber && arg.pageNumber > 1) {
          return {
            ...responseData,
            records: [...currentCacheData.records, ...responseData.records],
          };
        }

        return responseData;
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.pageNumber !== previousArg?.pageNumber ||
        !isEqual(currentArg, previousArg),
      providesTags: [API_TAGS.userOffers],
    }),
    toggleOfferFavourite: build.mutation<void, { offerId: string }>({
      query: ({ offerId }) => ({
        url: `/v1/user_offers/favourite/toggle/${offerId}`,
        method: 'POST',
      }),
      invalidatesTags: [API_TAGS.userOffers],
    }),
    favouriteOffers: build.query<IFavouritesOffersResponse, void>({
      query: () => ({
        url: '/v1/user_offers/favourite',
      }),
      providesTags: [API_TAGS.userOffers],
    }),
    offerById: build.query<IUserOffer, IUserOfferByIdParams>({
      query: ({ offerId }) => ({
        url: `/v1/user_offers/offers/${offerId}`,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetRewardsQuery,
  useRecurringRemittanceSettingsQuery,
  useRemittancesZakatTypesQuery,
  useUpdateRecurringRemittanceSettingsMutation,
  useDeleteRecurringRemittanceSettingsMutation,
  useOffersQuery,
  useToggleOfferFavouriteMutation,
  useFavouriteOffersQuery,
  useOfferByIdQuery,
} = rewardsApi;

export const {
  endpoints: {
    getRewards,
    recurringRemittanceSettings,
    remittancesZakatTypes,
    updateRecurringRemittanceSettings,
  },
  util,
} = rewardsApi;

export const { resetApiState } = util;

export const { initiate, useQueryState } = getRewards;
