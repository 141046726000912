export const fontWeights = {
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extraBold: 800,
  ultraBold: 900,
} as const;

export const fontSizes = {
  sm: '0.625rem', // 10px
  '2sm': '0.688rem', // 11px
  '3sm': '0.75rem', // 12px
  '4sm': '0.813rem', // 13px
  '5sm': '0.875rem', // 14px
  '6sm': '0.938rem', // 15px
  md: '1rem', // 16px
  '2md': '1.063rem', // 17px
  '3md': '1.125rem', // 18px
  '4md': '1.188rem', // 19px
  '5md': '1.25rem', // 20px
  '6md': '1.313rem', // 21px
  lg: '1.375rem', // 22px
  '2lg': '1.5rem', // 24px
  '3lg': '1.625rem', // 26px
  '4lg': '1.75rem', // 28px
  '5lg': '1.875rem', // 30px
  '6lg': '2rem', // 32px
  xl: '2.125rem', // 34px
  '2xl': '2.25rem', // 36px
  '3xl': '2.375rem', // 38px
  '4xl': '2.5rem', // 40px
  '5xl': '2.625rem', // 42px
  '6xl': '2.813rem', // 45 px
} as const;

export const lineHeights = {
  length: {
    xsm: '0.875rem', // 14px
    sm: '1rem', // 16px
    '2sm': '1.063rem', // 17px
    '3sm': '1.125rem', // 18px
    '4sm': '1.188rem', // 19px
    md: '1.25rem', // 20px
    '2md': '1.313rem', // 21px
    '3md': '1.375rem', // 22px
    '4md': '1.438rem', // 23px
    lg: '1.5rem', // 24px
    '2lg': '1.563rem', // 25px
    '3lg': '1.625rem', // 26px
    '4lg': '1.688rem', // 27px
    xl: '1.75rem', // 28px
    '2xl': '2rem', // 32px
    '3xl': '2.125rem', // 34px
    '4xl': '2.5rem', // 40px
    '5xl': '2.75rem', // 44px
    '6xl': '3.313rem', // 53px
    '7xl': '4.125rem', // 66px
    xxl: '8.75rem', // 140px
  },
  number: {
    sm: 0.5,
    md: 0.75,
    lg: 1,
    xl: 1.25,
    '2xl': 1.5,
    '3xl': 1.75,
    '4xl': 2,
  },
} as const;

export const letterSpacing = {
  tightest: '-0.048rem', // -0.768px
  tighter: '-0.024rem', // -0.384px
  tight: '-0.012rem', // -0.192px
  normal: '0rem', // 0px
  wide: '0.048rem', // 0.768px
  wider: '0.096rem', // 1.536px
  widest: '0.144rem', // 2.304px
} as const;
