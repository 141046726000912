export const ABOUT = 'about';
export const ALERTS = 'alerts';
export const RECURRING_WIDGET = 'RECURRING_WIDGET';
export const RECURRING_INVESTMENT = 'RECURRING_INVESTMENT';
export const HOME = 'home';
export const LOGIN = 'LOGIN';
export const RECOVER = 'RECOVER';
export const ROUND_UPS = 'ROUND_UPS';
export const VERIFY = 'VERIFY';
export const REGISTRATION = 'registration';
export const FOOTER = 'footer';
export const FEED = 'feed';
export const SETTINGS = 'SETTINGS';
export const SUPPORT = 'support';
export const PERFOMANCE = 'PERFOMANCE';
export const HISTORY = 'HISTORY';
export const ACCOUNT_STRENGTH = 'account_strength';
export const MODAL = 'MODAL';
export const INVEST = 'INVEST';
export const MENU = 'menu';
export const OFFSETTERS = 'OFFSETTERS';
export const KIDS = 'KIDS';
export const KIDS_DASHBOARD = 'KIDS_DASHBOARD';
export const SUPER = 'SUPER';
export const NOT_FOUND = 'NOT_FOUND';
export const OFFSETTER_DETAILS = 'OFFSETTER_DETAILS';
export const POTENTIAL_FREE_CASH = 'POTENTIAL_FREE_CASH';
export const REWARDS = 'REWARDS';
export const REWARDS_INTERNAL = 'REWARDS_INTERNAL';
export const STATEMENTS = 'STATEMENTS';
export const SUPER_REGISTRATION = 'SUPER_REGISTRATION';
export const SUPER_ROLLOVER = 'SUPER_ROLLOVER';
export const SUPER_INFO = 'SUPER_INFO';
export const MY_FINANCE_WIDGET = 'MY_FINANCE_WIDGET';
export const CHANGE_PORTFOLIO = 'CHANGE_PORTFOLIO';
export const HOME_PAGE = 'HOME';
export const CRS = 'CRS';
export const CPF = 'CPF';
export const RESET_PIN = 'RESET_PIN';
export const BLOG = 'BLOG';
export const RDV = 'RDV';
export const PAYMENT_GATEWAY = 'PAYMENT_GATEWAY';
export const KIDS_EDIT = 'KIDS_EDIT';
