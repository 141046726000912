// TODO: https://acornsau.atlassian.net/browse/RAIZ-6610
import { createSelector } from '@reduxjs/toolkit';

import { PATHS } from '../../constants/paths';

import { ACCEPTANCE_DOCUMENTS } from './consts';

import { AU_APP } from 'constants/localeConfigs';
import { ALERT_TYPES } from 'constants/alerts';
import { MIGRATION_SOURCES } from 'constants/migrationConstants';

export const selectZakatTypes = (state) =>
  state.user.zakatTypes?.reduce(
    (types, type) =>
      type?.enabled
        ? [
            ...types,
            {
              label: type.title,
              value: type.key,
            },
          ]
        : types,
    [],
  );

export const selectBlogPosts = (state) => state.user.posts || [];

export const selectUserEmail = (state) => state.user?.user?.email;

export const selectUserInvitation = (state) =>
  state?.user?.userInvitation?.invitation;

export const selectIsUserAlertOpen = (alertType) => (state) =>
  state?.user?.user?.alerts?.some((alert) => alert.type === alertType);

export const selectIsSuperUserAlertOpen = (alertType) => (state) =>
  state?.user?.superUser?.alerts?.some((alert) => alert.type === alertType);

export const selectAcceptanceDocuments = (state) =>
  state.user?.requiredDocuments?.acceptance_documents || [];

export const selectTermsDocument = createSelector(
  [selectAcceptanceDocuments],
  (acceptanceDocuments) =>
    acceptanceDocuments?.filter(
      (document) =>
        document?.document_type === ACCEPTANCE_DOCUMENTS.CUSTOMER_AGREEMENT,
    )?.[0] || {},
);

export const selectAcceptanceDocumentVersion = createSelector(
  [selectTermsDocument],
  (termsDocument) => termsDocument?.acceptance_document_version_uuid,
);

export const selectUser = (state) => state.user.user || {};

export const selectUserDataLoading = (state) => state.user?.isUserDataLoading;

export const selectIsPortfoliosLoading = (state) =>
  state.user?.isPortfoliosLoading;

export const selectUserAddress = createSelector([selectUser], (user) => ({
  street_number: user?.street_number,
  address1: user?.address1,
  city: user?.city,
  state: user?.state ? [{ label: user?.state, value: user?.state }] : [],
  zip: user?.zip,
}));

export const selectSecurityQuestions = (state) =>
  state.user.securityQuestions || [{ label: '', value: '' }];

export const selectUserDetails = createSelector([selectUser], (user) => ({
  first_name: user?.first_name,
  last_name: user?.last_name,
  phone_number: user?.phone_number,
  dob: user?.dob,
  us_resident: user?.us_resident || false,
  question1: user?.question1
    ? [{ label: user?.question1_value, value: user?.question1 }]
    : [],
}));

export const selectIsUserMigrationCompleted = createSelector(
  [selectUser],
  (user) =>
    !!user?.registration_complete &&
    user?.source === MIGRATION_SOURCES.superEstate,
);

export const selectUserErrorMessage = (state) => state.user.errorMessage;

export const selectIsSuperAnnuationInsuranceAccepted = createSelector(
  [selectUser],
  (user) => user?.super_annuation_insurance || false,
);

export const selectCloseAccountData = (state) => {
  const { user, errorMessage, isCloseAccountLoading, smsfDetails } =
    state?.user || {};

  const legalFundName = smsfDetails?.details?.legal_fund_name;

  const userFirstName = user.first_name;
  const userLastName = user.last_name ? ` ${user.last_name}` : '';

  const isCloseAccountRequestCreated =
    !!state.user.closeAccountRequesStatus?.account_closure_request?.created_at;

  const userBalance = user?.current_balance || 0;
  const userEmail = user?.email;
  const userName = legalFundName || `${userFirstName}${userLastName}`;
  const isFundingAccountLinked = !!state?.user?.questions?.has_funding_source;
  const isAccountClosed = !!user?.closed_at;

  const questionFormatter = (question) => ({
    value: question.key,
    label: question.label,
    placeholder: question?.question?.placeholder || '',
    options: question?.question?.options?.map?.(questionFormatter) || [],
  });

  const questions =
    state?.user?.questions?.questions?.[0]?.options?.map(questionFormatter) ||
    [];

  return {
    errorMessage,
    isAccountClosed,
    isCloseAccountLoading,
    isCloseAccountRequestCreated,
    isFundingAccountLinked,
    questions,
    userBalance,
    userEmail,
    userName,
  };
};

export const selectUserAllocationProfileId = (isSuper) => (state) => {
  if (isSuper) {
    return state?.user?.superUser?.allocation_profile_id || '';
  }

  return state.user?.user?.allocation_profile_id;
};

export const selectSuperUser = (state) => state?.user?.superUser || {};

export const selectLoginEmail = (state) => state.user.loginData?.email || '';

export const selectBrandCampaignId = (state) => state.rewards.brand.campaign_id;

export const selectCustomPortfolioCategories = (state) =>
  state.user?.portfolioCategories?.labels?.reduce(
    (acc, label) => [...acc, { ...label, category: label.slice_label }],
    [],
  ) || [];

export const selectCustomPortfolioEtfs = (state) =>
  state.user?.customPortfolio?.portfolio?.etfs?.reduce(
    (acc, etf) => [
      ...acc,
      { ...etf, category: etf.slice_label, percentage: etf.amount },
    ],
    [],
  ) || [];

export const selectDependentUserAccess = (state) =>
  state.user?.dependentUserAccess || {};

export const selectVerificationToken = (state) => state.user.verificationToken;

export const selectIsUserLoading = (state) => state.user?.isLoading || false;

export const selectCurrentUser = (state) => state.user;

export const selectIsFundingErrorModalOpen = (state) =>
  state.user?.isFundingErrorModalOpen;

export const selectFundingErrorMessage = (state) =>
  state.user?.fundingErrorMessage;

export const selectIsSecurityQuestionsLoading = (state) =>
  state.user?.isSecurityQuestionsLoading;

export const selectAccountStrength = (state) => state.user?.accountStrength;

export const selectRemittances = (state) => state.user?.remittances;

export const selectIsSuperRegistration = createSelector(
  [
    selectUser,
    selectIsSuperUserAlertOpen(ALERT_TYPES.registration.consentPending),
  ],
  (user, isConsentPendingAlert) =>
    (!user?.super_annuation_user || isConsentPendingAlert) &&
    user.registration_complete,
);

export const selectIsUserHasFastlink = (state) =>
  state.user?.user?.has_fastlink;

export const selectRegistrationPreviousStep = (state) =>
  state.user?.previousStep || PATHS.registration.personal.accountQuestions;

export const selectIsLinkWithdrawalAccountModalOpen = (state) => {
  if (AU_APP) {
    return false;
  }

  const matchingAlerts = state.user?.alerts?.filter(
    (alert) =>
      alert.type === ALERT_TYPES.registration.incompleteWithdrawalAccount,
  );

  return matchingAlerts?.length > 0;
};
