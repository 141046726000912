import styled from '@emotion/styled';

export interface IAdditionalFieldWrapper {
  isUppercase: boolean;
}

export const AdditionalFieldWrapper = styled.div<IAdditionalFieldWrapper>`
  ${({ isUppercase }) => isUppercase && 'label {text-transform: uppercase;}'}
`;

export const SuitabilitiesQuestionsLabel = styled.div<IAdditionalFieldWrapper>`
  ${({ isUppercase }) => isUppercase && 'text-transform: uppercase;'}
`;
