import { generatePath, useNavigate } from 'react-router-dom';

import {
  IconWrapper,
  Title,
  Item,
  FavouriteIconWrapper,
  Description,
} from './styles';

import { IconButton } from 'components/elements/IconButton';
import { ImageWithDefaultIcon } from 'components/elements/ImageWithDefaultIcon';
import { RewardStatus } from 'pages/NewRewards/components/RewardStatus/RewardStatus';
import { useToggleOfferFavouriteMutation } from 'store/rewards/api';
import { IUserOffer } from 'store/rewards/api/types';
import { PATHS } from 'constants/paths';
import { useAutoRewardInfoModal } from 'pages/NewRewards/hooks/useAutoRewardInfoModal';
import { OFFER_TYPES } from 'pages/NewRewards/consts';
import { useRewardsCurrentTab } from 'pages/NewRewards/hooks/useRewardsCurrentTab';

interface RewardsListItemProps {
  reward: IUserOffer;
  isFavouriteCategory?: boolean;
  setPageNumber: (pageNumber: number) => void;
}

export const RewardsListItem = ({
  reward,
  isFavouriteCategory,
  setPageNumber,
}: RewardsListItemProps) => {
  const navigate = useNavigate();
  const [toggleOfferFavourite] = useToggleOfferFavouriteMutation();

  const { currentOfferType } = useRewardsCurrentTab();

  const { handleOpenAutoRewardsInfoModal } = useAutoRewardInfoModal();

  const handleOfferClick = () => {
    if (currentOfferType === OFFER_TYPES.auto && !reward.sourceActive) {
      handleOpenAutoRewardsInfoModal(reward);

      return;
    }

    navigate(
      generatePath(PATHS.rewards.online.index, {
        id: reward.id || reward.offerId,
      }),
    );
  };

  return (
    <Item onClick={handleOfferClick}>
      <IconWrapper>
        <ImageWithDefaultIcon
          src={reward.logo || reward.logoUrl}
          alt={reward.title || reward.offerTitle}
          defaultIcon="placeholder"
          defaultIconSize={100}
          width={100}
        />

        <FavouriteIconWrapper>
          <IconButton
            iconName={
              reward.isFavourite || isFavouriteCategory
                ? 'likeActive'
                : 'likeInactive'
            }
            onClick={(e: React.FormEvent<HTMLButtonElement>) => {
              e.stopPropagation();

              setPageNumber(1);

              toggleOfferFavourite({ offerId: reward.id || reward.offerId });
            }}
            size={20}
          />
        </FavouriteIconWrapper>
      </IconWrapper>

      {reward.offerType === OFFER_TYPES.auto && (
        <RewardStatus
          isActive={reward.sourceActive}
          sourceType={reward.sourceType}
        />
      )}

      <Title>{reward.advertiser}</Title>

      <Description>{reward.title || reward.offerTitle}</Description>
    </Item>
  );
};
