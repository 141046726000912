import { API_TAGS } from '../consts';

import {
  IGetLinkStatesResponse,
  IGetInstitutionByIdResponse,
  IGetInstitutionByIdParams,
  IVerificationStatusResponse,
  ICheckIsAccountLinkableParams,
} from './types';

import { api } from 'store/api';

export const FUNDING_ACCOUNT_BASE_URL = '/v1/funding_sources';

export const fundingAccountApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLinkStates: build.query<IGetLinkStatesResponse, void>({
      query: () => ({ url: '/v1/institutions/link_states', method: 'GET' }),
    }),
    verificationStatus: build.query<IVerificationStatusResponse, void>({
      query: () => ({ url: `${FUNDING_ACCOUNT_BASE_URL}/verification_status` }),
      providesTags: [API_TAGS.verificationStatus],
    }),
    cancelVerification: build.mutation<IVerificationStatusResponse, void>({
      query: () => ({
        url: `${FUNDING_ACCOUNT_BASE_URL}/cancel_verification`,
        method: 'POST',
      }),
      invalidatesTags: [API_TAGS.verificationStatus],
    }),
    institutionById: build.query<
      IGetInstitutionByIdResponse,
      IGetInstitutionByIdParams
    >({
      query: ({ id, additionalParams }) => ({
        url: `/v1/institutions/${id}`,
        params: additionalParams,
        method: 'GET',
      }),
    }),
    checkIsAccountLinkable: build.query<void, ICheckIsAccountLinkableParams>({
      query: ({ id }) => ({
        url: `/v1/accounts/${id}/linkable`,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetLinkStatesQuery,
  useVerificationStatusQuery,
  useCancelVerificationMutation,
  useInstitutionByIdQuery,
} = fundingAccountApi;

export const {
  endpoints: { getLinkStates, institutionById, checkIsAccountLinkable },
} = fundingAccountApi;

export const {
  useQueryState: useInstitutionByIdQueryState,
  initiate,
  select,
} = institutionById;

export const { initiate: initiateCheckIsAccountLinkable } =
  checkIsAccountLinkable;
