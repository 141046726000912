import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IN_STORE_PAGE } from '../../../constants/comonConstants';
import { FAVOURITE_CATEGORY_ID } from '../consts';

import OfferDistance from './OfferDistance';

export default function InstoreOfferItem({
  offer,
  toggleLikeOffer,
  categoryId,
}) {
  const { locations, id, coverArt, logo, advertiser, title, isFavourite } =
    offer;

  const isFavouriteCategory = categoryId === FAVOURITE_CATEGORY_ID;
  const isFavouriteOffer = isFavourite || isFavouriteCategory;

  return (
    <div className="rewards-block">
      <Link to={`${IN_STORE_PAGE}/${id}`}>
        <div className="grid__container-item">
          <div className="grid__container-image">
            <div
              className="grid__container-logo"
              style={{
                background: `url(${coverArt}) no-repeat`,
                backgroundSize: 'cover',
              }}
            />
          </div>

          <div className="grid__container-bottom-part">
            <div className="brand-icon-placeholder" />

            <div
              className="brand-icon"
              style={{
                background: `url(${logo}) no-repeat`,
                backgroundSize: 'cover',
              }}
            />

            <div className="brand-title-block">
              <div>{advertiser}</div>

              <div>{title}</div>
            </div>

            <div className="distance">
              <div className="marker-icon">
                <div>
                  <OfferDistance locations={locations} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div
        className={isFavouriteOffer ? 'like-instore' : 'unlike-instore'}
        onClick={() => {
          toggleLikeOffer({
            isFavourite: isFavouriteOffer,
            id,
            isInstore: true,
            isShowFavourite: isFavouriteCategory,
          });
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
      />
    </div>
  );
}

InstoreOfferItem.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    logo: PropTypes.string,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        distance: PropTypes.number,
      }),
    ),
    coverArt: PropTypes.string,
    advertiser: PropTypes.string,
    isFavourite: PropTypes.bool,
  }).isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  categoryId: PropTypes.number.isRequired,
};
