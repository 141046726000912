import { useTranslation } from 'react-i18next';

import { Content, Status, Wrapper } from './styles';
import { RewardStatusIcon } from './RewardStatusIcon';

import { Icon } from 'components/elements/Icon';

interface IRewardStatusProps {
  isActive: boolean;
  sourceType: string;
}

export const RewardStatus = ({ isActive, sourceType }: IRewardStatusProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Content isActive={isActive}>
        <RewardStatusIcon isActive={isActive} sourceType={sourceType} />

        <Status isActive={isActive}>
          {isActive ? t('common.status.active') : t('common.status.inactive')}
        </Status>

        {!isActive && <Icon iconName="info" size={14} />}
      </Content>
    </Wrapper>
  );
};
